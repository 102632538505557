import {
  Container,
  Heading,
  Divider,
  Image,
  Flex,
  Text,
} from "@chakra-ui/react";
import Projects from "../../components/Projects";
import Progress from "../../components/Progress";
import Comments from "../../components/Comments";

export default function Home() {
  return (
    <>
      <Flex
        justifyContent={"center"}
        alignItems={"center"}
        position={"relative"}
        h={["100vh"]}
      >
        <Image
          src={require("../../assets/other/eyidenart-logo.png")}
          alt="eyidenart logo"
          objectFit={"contain"}
          boxSize={["xs", "md", "lg", "lg", "xl", "2xl"]}
          zIndex={1}
        />
        <div
          style={{
            position: "absolute",
            top: "0",
            width: "100%",
            height: "100%",
            backgroundImage:
              "radial-gradient(circle, rgba(188,138,95,0.5), white)",
          }}
        ></div>
        <Text
          zIndex={1}
          fontSize={["lg", "xl", "2xl", "3xl"]}
          position={"absolute"}
          bottom={20}
          left={0}
          px={5}
          w={"100%"}
          textAlign={"center"}
        >
          İçinizdeki sanat duvarlarınızda
        </Text>
      </Flex>
      <Container
        maxW={["container.2xl", "container.2xl", "container.xl"]}
        mt={20}
      >
        <Heading as="h2" size={"lg"} mb={5} textAlign={"center"}>
          Çalışmalarımız
        </Heading>
        <Divider mb={5} />
        <Projects />
        <Heading as="h2" size={"lg"} mt={10} mb={5} textAlign={"center"}>
          Süreç Nasıl İşliyor?
        </Heading>
        <Divider mb={5} />
        <Progress />
        <Heading as="h2" size={"lg"} mt={10} mb={5} textAlign={"center"}>
          Müşterilerin Yorumları
        </Heading>
        <Divider mb={5} />
        <Comments />
      </Container>
    </>
  );
}
