import { createSlice } from "@reduxjs/toolkit";

export const progressSlice = createSlice({
  name: "progress",
  initialState: {
    items: [
      {
        icon: "fa-solid fa-pen",
        color: "#7161EF",
        title: "Aşama 1",
        description:
          "İşyerleriniz ya da eviniz için istediğiniz resim konusunda kararsızsanız, örnek görseller üzerinden yardımcı olunuyor. İstediğiniz çizim belli ise dijital ortamda çiziminiz hazırlanıyor.",
      },
      {
        icon: "fa-solid fa-users",
        color: "#D62828",
        title: "Aşama 2",
        description:
          "Çizimin detayları, renkleri ve ne kadar sürede bitirileceği göz önüne alınarak fiyat belirleniyor. İstediğiniz resim dijital olarak hazırlanıyor ve nereye isterseniz yansıtılarak, nasıl görüneceği gösteriliyor.",
      },
      {
        icon: "fa-solid fa-paintbrush",
        color: "#F77F00",
        title: "Aşama 3",
        description:
          "Uygun olan vakitlerde çizime başlanıyor. İstenilen son rötuşlar atılarak çizim süreci bitiyor. Resmin boyutu ve renklendirilmesine göre süre değişkenlik gösterebilir.",
      },
      {
        icon: "fa-solid fa-thumbs-up",
        color: "#3A6EA5",
        title: "Aşama 4",
        description:
          "Hayallerinizin duvara yansıma keyfini çıkarıyorsunuz. İşletmelerinizin en dikkat çekici noktalarına kavuşuyorsunuz.",
      },
    ],
  },
  reducers: {},
});

export default progressSlice.reducer;
