import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Contact from "./pages/Contact";
import AboutUs from "./pages/About Us";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPen,
  faUsers,
  faPaintBrush,
  faThumbsUp,
  fa1,
  fa2,
  fa3,
  fa4,
} from "@fortawesome/free-solid-svg-icons";
import Footer from "./components/Footer";

library.add(faPen, faUsers, faPaintBrush, faThumbsUp, fa1, fa2, fa3, fa4);

function App() {
  return (
    <div>
      <Router>
        <Navbar />

        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/hakkimizda" component={AboutUs} />
          <Route path="/iletisim" component={Contact} />
        </Switch>
      </Router>

      <Footer />
    </div>
  );
}

export default App;
