import {
  Flex,
  Spacer,
  Tabs,
  TabList,
  Tab,
  Button,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
  Box,
  HStack,
  DrawerFooter,
  Divider,
  Text,
  Image,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faThreads,
  faFacebook,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";

export default function Navbar() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <nav>
        <Flex
          alignItems={"center"}
          gap={[2, 3, 5]}
          mx={[10, 10, 20, 20, 40]}
          backgroundColor={"transparent"}
        >
          <Image
            src={require("../../assets/other/eyidenart-logo.png")}
            alt="eyidenart logo"
            objectFit={"contain"}
            w={["100px", "100px", "100px", "120px"]}
          />
          <Spacer />
          <Tabs
            align="end"
            variant={"unstyled"}
            display={["none", "none", "block"]}
          >
            <TabList>
              <Tab>
                <Link to="/">
                  <Text fontWeight={"480"}>Ana Sayfa</Text>
                </Link>
              </Tab>
              <Tab>
                <Link to="/hakkimizda">
                  <Text fontWeight={"480"}>Hakkımızda</Text>
                </Link>
              </Tab>
              <Button>
                <Link to="/iletisim">İletişim</Link>
              </Button>
            </TabList>
          </Tabs>
          <Box onClick={onOpen} display={["block", "block", "none"]}>
            <FontAwesomeIcon icon={faBars} size="xl" />
          </Box>

          <HStack justifyContent={"center"} display={["none", "none", "block"]}>
            <a
              style={{ marginLeft: "6px" }}
              href="https://instagram.com/eyidenart?igshid=NGVhN2U2NjQ0Yg%3D%3D&utm_source=qr"
              target="_blank"
            >
              <FontAwesomeIcon
                icon={faInstagram}
                size="xl"
                style={{ color: "#E1306C" }}
              />
            </a>
            <a
              style={{ marginLeft: "6px" }}
              href="https://www.threads.net/@eyidenart"
              target="_blank"
            >
              <FontAwesomeIcon icon={faThreads} size="xl" />
            </a>
            <a
              style={{ marginLeft: "6px" }}
              href="https://www.facebook.com/profile.php?id=100068383813203&mibextid=LQQJ4d"
              target="_blank"
            >
              <FontAwesomeIcon
                icon={faFacebook}
                size="xl"
                style={{ color: "#4267B2" }}
              />
            </a>
            <a style={{ marginLeft: "6px" }} href="https://wa.me/05398988198">
              <FontAwesomeIcon
                icon={faWhatsapp}
                size="xl"
                style={{ color: "#25D366" }}
              />
            </a>
          </HStack>
        </Flex>
      </nav>
      <Drawer isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Sekmeler</DrawerHeader>
          <DrawerBody>
            <Flex direction={"column"} gap={3}>
              <Link to="/" onClick={onClose}>
                Ana Sayfa
              </Link>
              <Link to="/hakkimizda" onClick={onClose}>
                Hakkımızda
              </Link>
              <Link to="/iletisim" onClick={onClose}>
                <Button colorScheme="gray">İletişim</Button>
              </Link>
            </Flex>
          </DrawerBody>
          <Divider />
          <DrawerFooter>
            <HStack>
              <Text fontSize={"md"}>Follow me</Text>
              <a
                href="https://instagram.com/eyidenart?igshid=NGVhN2U2NjQ0Yg%3D%3D&utm_source=qr"
                target="_blank"
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  size="xl"
                  style={{ color: "#E1306C" }}
                />
              </a>
              <a href="https://www.threads.net/@eyidenart" target="_blank">
                <FontAwesomeIcon icon={faThreads} size="xl" />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=100068383813203&mibextid=LQQJ4d"
                target="_blank"
              >
                <FontAwesomeIcon
                  icon={faFacebook}
                  size="xl"
                  style={{ color: "#4267B2" }}
                />
              </a>
              <a href="https://wa.me/05398988198">
                <FontAwesomeIcon
                  icon={faWhatsapp}
                  size="xl"
                  style={{ color: "#25D366" }}
                />
              </a>
            </HStack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
