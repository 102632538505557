import { createSlice } from "@reduxjs/toolkit";

export const commentsSlice = createSlice({
  name: "comments",
  initialState: {
    items: [
      {
        title: "Tam bir sanatçı!",
        body: "Fabrika Training Club olarak işletmemizi yenileme kararı aldığımızda daha önceden de tanıdığım için Eyidenart’la görüştüm. Denizli’deki iki salonumuzu da profesyonel dokunuşlarla yenilemiş olduk. Çizim yaptırmak isteyen herkese Eyidenart’ı gönül rahatlığıyla tavsiye edebilirim.",
        owner: {
          name: "",
          surname: "",
          company: "Fabrika Training Club Spor Salonu",
          jobTitle: "",
        },
        tags: ["tasarım", "çizim", "duvar boyama"],
      },
      {
        title: "İşini hakkıyla yapıyor",
        body: "Eyidenart instagramda reklam olarak karşıma çıktı. Sayfayı incelediğimde çizimler hoşuma gitti. Kendisi çok güler yüzlü ve anlaşması kolay biri olduğu için süreç çok rahat ilerledi. Beraber nasıl bir tasarım yapabileceğimizi çalıştıktan sonra ortaya harika bir sonuç çıktı. Emeği için kendisine teşekkür ederim.",
        owner: {
          name: "",
          surname: "",
          company: "",
          jobTitle: "Kafe Sahibi",
        },
        tags: ["çizim", "duvar boyama", "tasarım"],
      },
      {
        title: "Harika bir sanatçı",
        body: "Behçet Bey’in öncelikle emeklerine sağlık, harikalar yarattı okulumuzda. Büyük küçük herkesin dikkatini çekecek çizimleri ile her biri bizim fotoğraf köşemiz oldu resmen :) Özgüvenli, kendinden emin ve elinin yeteneğini gerçekten ortaya koyan biri. Memnun kalmama, anlaşamama gibi bir durum ise söz konusu bile değil. İyi ki okulumuzu onun ellerine bıraktık, sonuçlar harikaa!",
        owner: {
          name: "",
          surname: "",
          company: "",
          jobTitle: "Okul Müdürü",
        },
        tags: ["çizim", "duvar boyama"],
      },
    ],
  },
  reducers: {},
});

export default commentsSlice.reducer;
