import {
  Flex,
  Container,
  Image,
  Heading,
  Text,
  Box,
  Divider,
  HStack,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faFacebook,
  faThreads,
} from "@fortawesome/free-brands-svg-icons";

export default function AboutUs() {
  return (
    <>
      <Container maxW={["container.xl", "container.xl", "container.lg"]}>
        <Flex
          direction={["column", "column", "row"]}
          mt={[20, 20, 10, 0]}
          h={["90vh", "90vh", "80vh", "85vh", "100vh"]}
          justifyContent={"center"}
          alignItems={"center"}
          gap={10}
        >
          <Image
            src={require("../../assets/other/me.jpg")}
            boxSize={["250px", "xs", "xs", "xs", "sm"]}
            borderRadius={"2xl"}
          />
          <Box>
            <Heading as="h2" size={["md", "md", "lg"]} color={"green.500"}>
              EyidenArt Kimdir?
            </Heading>
            <Divider my={[3, 3, 5]} />
            <Text fontSize={["sm", "sm", "md"]}>
              Merhaba! Ben Behçet Eyiden. Eyidenart, üniversite yıllarımda resim
              sanatına olan ilgimle doğdu. 2018 yılından bu yana, karakalem
              portre, dijital resim ve duvar sanatı alanlarında profesyonel
              olarak faaliyet gösteriyorum. Eyidenart’da içindeki sanatı dışa
              vurmak isteyen herkesle birlikte yaşamın her alanına keyif katmak
              için çalışıyorum. Siz de içinizdeki sanatı keşfedin ve hayatınıza
              renk katın!
            </Text>
            <Box mt={5}>
              <HStack justifyContent={"right"}>
                <Text fontSize={["sm", "sm", "md"]}>Social: </Text>
                <a
                  href="https://instagram.com/eyidenart?igshid=NGVhN2U2NjQ0Yg%3D%3D&utm_source=qr"
                  target="_blank"
                >
                  <FontAwesomeIcon
                    icon={faInstagram}
                    size="xl"
                    style={{ color: "#E1306C" }}
                  />
                </a>
                <a href="https://www.threads.net/@eyidenart" target="_blank">
                  <FontAwesomeIcon icon={faThreads} size="xl" />
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=100068383813203&mibextid=LQQJ4d"
                  target="_blank"
                >
                  <FontAwesomeIcon
                    icon={faFacebook}
                    size="xl"
                    style={{ color: "#4267B2" }}
                  />
                </a>
              </HStack>
            </Box>
          </Box>
        </Flex>
      </Container>
    </>
  );
}
