import { Container, Flex, Image, Text, VStack } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";

export default function Contact() {
  return (
    <>
      <Container maxW={"container.2xl"} position={"relative"}>
        <Flex
          direction={["column", "column", "row"]}
          justifyContent={"center"}
          h={"100vh"}
          alignItems={"center"}
          gap={[0, 5, 5, 10]}
        >
          <Image
            objectFit={"contain"}
            boxSize={["xs", "sm", "md", "md", "lg"]}
            src={require("../../assets/other/eyidenart-logo.png")}
            alt="eyidenart logo"
            zIndex={2}
          />
          <div
            style={{
              position: "absolute",
              top: "0",
              width: "100%",
              height: "100%",
              backgroundImage:
                "radial-gradient(circle, rgba(188,138,95,0.5), white)",
            }}
          ></div>

          <Flex
            direction={["row", "row", "column"]}
            gap={[5, 3, 5]}
            wrap={"wrap"}
            justifyContent={"center"}
            alignItems={"center"}
            textAlign={"center"}
            zIndex={2}
          >
            <VStack>
              <FontAwesomeIcon
                icon={faEnvelope}
                className="contact-icon"
                style={{ color: "#417ee6" }}
              />
              <Text fontSize={["md", "md", "lg"]}>behceteyiden@gmail.com</Text>
            </VStack>
            <VStack>
              <FontAwesomeIcon
                icon={faPhone}
                className="contact-icon"
                style={{ color: "#3cd763" }}
              />
              <Text fontSize={["md", "md", "lg"]}>(537)735 86 08</Text>
            </VStack>
            <VStack>
              <FontAwesomeIcon
                icon={faLocationDot}
                className="contact-icon"
                style={{ color: "#ff0000" }}
              />
              <Text fontSize={["md", "md", "lg"]}>
                XXX Mah. XXX Sok. <br />
                Merkezefendi/Denizli
              </Text>
            </VStack>
          </Flex>
        </Flex>
      </Container>
    </>
  );
}
