import { configureStore } from "@reduxjs/toolkit";
import servicesReducer from "./services/servicesSlice";
import commentsReducer from "./comments/commentsSlice";
import projectsReducer from "./projects/projectsSlice";
import progressReducer from "./progress/progressSlice";

export const store = configureStore({
  reducer: {
    services: servicesReducer,
    comments: commentsReducer,
    projects: projectsReducer,
    progress: progressReducer,
  },
});
