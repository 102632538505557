import {
  Card,
  CardBody,
  CardFooter,
  Heading,
  Text,
  Badge,
  Flex,
  HStack,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";

export default function Comments() {
  const comments = useSelector((state) => state.comments.items);

  return (
    <>
      <Flex gap={[1, 1, 2, 3]} flexWrap={"wrap"} justifyContent={"center"}>
        {comments.map((comment, i) => {
          return (
            <Card key={i} w={["sm", "xs", "sm"]}>
              <CardBody>
                <Heading as="h3" size={"md"}>
                  {comment.title}
                </Heading>
                <Flex wrap={"wrap"} my={2} gap={1}>
                  {comment.tags.map((tag, i) => {
                    return (
                      <Badge key={i} colorScheme="red">
                        {tag}
                      </Badge>
                    );
                  })}
                </Flex>
                <Text fontSize={"md"}>{comment.body}</Text>
              </CardBody>
              <CardFooter>
                <Text fontSize={"sm"}>
                  <i>
                    {comment.owner.name} {comment.owner.surname}{" "}
                    <Badge colorScheme="purple">{comment.owner.jobTitle}</Badge>
                    <br />
                    {comment.owner.company}
                  </i>
                </Text>
              </CardFooter>
            </Card>
          );
        })}
      </Flex>
    </>
  );
}
