import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import ProjectCard from "../ProjectCard";

export default function Projects() {
  const projects = useSelector((state) => state.projects.items);

  return (
    <>
      <Swiper
        spaceBetween={30}
        slidesPerView={1}
        breakpoints={{
          480: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 100,
          },
          992: {
            slidesPerView: 4,
            spaceBetween: 100,
          },
          1280: {
            slidesPerView: 5,
            spaceBetween: 200,
          },
        }}
        onSlideChange={() => console.log("Slide chande")}
        onSwiper={(swiper) => console.log(swiper)}
      >
        {projects.map((project, i) => {
          return (
            <SwiperSlide key={i}>
              <ProjectCard project={project} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}
