import { Card, Image } from "@chakra-ui/react";
import { useState } from "react";

export default function ProjectCard({ project }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Card
        className="project-card"
        w={["100%", "80%", "280px"]}
        onClick={() => setIsOpen(!isOpen)}
        position={"relative"}
      >
        <Image
          borderRadius={"sm"}
          src={require(`../../assets/artworks/${project.imageUrl}`)}
          alt={"Çalışma"}
          style={{ boxShadow: "0 0 3.5px #333" }}
          position={"relative"}
        />
      </Card>
    </>
  );
}
