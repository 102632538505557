import { createSlice } from "@reduxjs/toolkit";

export const projectsSlice = createSlice({
  name: "projects",
  initialState: {
    items: [
      {
        imageUrl: "1.jpg",
        title: "Title",
        categories: ["duvar boyama", "tasarım"],
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque, corporis.",
      },
      {
        imageUrl: "2.jpg",
        title: "Title",
        categories: ["çizim", "duvar boyama"],
        description:
          "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Similique aliquam quia nisi magnam adipisci aliquid eius voluptates?",
      },
      {
        imageUrl: "3.jpg",
        title: "Title",
        categories: ["çizim"],
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque, corporis.",
      },
      {
        imageUrl: "4.jpg",
        title: "Title",
        categories: ["duvar boyama"],
        description:
          "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Similique aliquam quia nisi magnam adipisci aliquid eius voluptates?",
      },
      {
        imageUrl: "5.jpg",
        title: "Title",
        categories: ["boyama"],
        description:
          "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iste magni modi aliquam?",
      },
      {
        imageUrl: "6.jpg",
        title: "Title",
        categories: ["tasarım", "duvar boyama"],
        description:
          "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Similique aliquam quia nisi magnam adipisci aliquid eius voluptates?",
      },
      {
        imageUrl: "7.jpg",
        title: "Title",
        categories: ["duvar boyama", "çizim", "tasarım"],
        description:
          "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iste magni modi aliquam?",
      },
      {
        imageUrl: "8.jpg",
        title: "Title",
        categories: ["tasarım"],
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque, corporis.",
      },
      {
        imageUrl: "9.jpg",
        title: "Title",
        categories: ["tasarım", "çizim", "duvar boyama"],
        description:
          "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Similique aliquam quia nisi magnam adipisci aliquid eius voluptates?",
      },
      {
        imageUrl: "10.jpg",
        title: "Title",
        categories: ["duvar boyama"],
        description:
          "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iste magni modi aliquam?",
      },
    ],
  },
  reducers: {},
});

export default projectsSlice.reducer;
