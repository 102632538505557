import { createSlice } from "@reduxjs/toolkit";

export const servicesSlice = createSlice({
  name: "services",
  initialState: {
    items: [
      {
        title: "Service I",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia omnis cupiditate quos, earum labore eaque repellendus velit debitis ullam eligendi.",
        iconCode: "fa-solid fa-paintbrush",
        time: "10 gün",
        color: "#ff3d9b",
      },
      {
        title: "Service II",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Blanditiis aliquid incidunt facere, explicabo nihil adipisci vitae architecto expedita quia iste eius eveniet recusandae eaque.",
        iconCode: "fa-solid fa-palette",
        time: "2 gün",
        color: "#50ce8d",
      },
    ],
  },
  reducers: {},
});

export default servicesSlice.reducer;
