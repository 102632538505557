import { Box, Flex, Text } from "@chakra-ui/react";

export default function Footer() {
  return (
    <footer>
      <Box backgroundColor={"gray.600"} h={"100px"} color={"white"} mt={10}>
        <Flex
          justifyContent={"center"}
          alignItems={"center"}
          w={"100%"}
          h={"100%"}
        >
          <Text fontSize={["md", "md", "lg"]}>
            Powered by{" "}
            <span style={{ color: "#26a7de" }}>
              <i>WetaTech</i>
            </span>
          </Text>
        </Flex>
      </Box>
    </footer>
  );
}
