import {
  Card,
  CardHeader,
  CardBody,
  Heading,
  Text,
  Flex,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Progress() {
  const progressSteps = useSelector((state) => state.progress.items);

  return (
    <>
      <Flex justifyContent={"center"} gap={2} wrap={["wrap", "wrap", "nowrap"]}>
        {progressSteps.map((item, i) => {
          return (
            <Card
              key={i}
              w={["xs", "45%", "sm", "500px"]}
              backgroundColor={"blackAlpha.50"}
            >
              <CardHeader>
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className="progress"
                    style={{ backgroundColor: item.color }}
                  >
                    <FontAwesomeIcon
                      icon={item.icon}
                      style={{ color: "#fff" }}
                    />
                    <div className="progress-step">
                      <Text fontSize={["sm", "md", "lg"]}>
                        <FontAwesomeIcon
                          icon={`fa-solid fa-${i + 1}`}
                          style={{ color: item.color }}
                        />
                      </Text>
                    </div>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <Heading as="h3" size={["sm", "md"]}>
                  {item.title}
                </Heading>
                <Text mt={2} fontSize={["xs", "sm", "sm", "md"]}>
                  {item.description}
                </Text>
              </CardBody>
            </Card>
          );
        })}
      </Flex>
    </>
  );
}
